
import {
    alertController,
    IonButton,
    IonButtons,
    IonContent, IonDatetime,
    IonHeader,
    IonIcon,
    IonItem, IonLabel, IonList, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar,
    modalController
} from "@ionic/vue";
import {arrowForwardOutline, checkmarkOutline} from "ionicons/icons";
import {defineComponent} from "vue";
import moment from "moment";

export default defineComponent({
    name: "EditMessageTemplateSettings",
    props: ['input'],
    components: {
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonIcon,
        IonTitle,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        IonSelect,
        IonSelectOption,
        IonDatetime
    },
    computed: {
        eventDate(){
            const account = this.$store.getters.account
            if(account && account.eventDate){
                return account.eventDate
            }
            return null
        },
        maxMessageDate(){
            return moment(this.eventDate).add(1, 'weeks').format('YYYY-MM-DD')
        },
        minMessageDate(){
            const account = this.$store.getters.account
            const date = moment(account.eventDate).subtract(1, 'month')
            const now = moment()
            if(date < now){
                return now.format('YYYY-MM-DD')
            }
            return date.format('YYYY-MM-DD')
        }
    },
    data(){
        return {
            icons: {
                arrowForwardOutline,
                checkmarkOutline
            },
            messageTemplate: {},
            initialMessageTemplate: {},
            types: {
                'round-1' : 'תאריך ושעה לסבב ראשון',
                'round-2' : 'תאריך ושעה לסבב שני',
                'round-3' : 'תאריך ושעה לסבב שלישי',
                'round-4' : 'תאריך ושעה לסבב רביעי',
                'round-5' : 'תאריך ושעה לסבב חמישי',
            }
        }
    },
    methods: {
        async back(){
            if(JSON.stringify(this.initialMessageTemplate) !== JSON.stringify(this.messageTemplate)){
                const alert = await alertController.create({
                    message: 'לצאת מבלי לשמור שינויים ?',
                    buttons: [
                        {
                            text: 'ביטול',
                            role: 'cancel'
                        },
                        {
                            text: 'אישור',
                            role: 'confirm'
                        }
                    ]
                })

                await alert.present()
                const {role} = await alert.onDidDismiss()
                if(role === 'confirm'){
                    await modalController.dismiss(null, 'cancel')
                }
            }else{
                await modalController.dismiss( null, 'cancel')
            }
        },
        async save(){
            await modalController.dismiss(this.messageTemplate, 'save')
        }
    },
    mounted(){
        if(this.input){
            this.messageTemplate = JSON.parse(JSON.stringify(this.input))
            this.initialMessageTemplate = JSON.parse(JSON.stringify(this.input))
        }
    }
})
