
import {defineComponent} from "vue";
import {
    IonButton,
    IonCard,
    IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent, IonDatetime,
    IonIcon, IonItem, IonLabel,
    IonPage,
    modalController, toastController
} from "@ionic/vue";
import {createOutline} from "ionicons/icons";
import EditMessageTemplate from "@/modals/EditMessageTemplate.vue";
import EditMessageTemplateSettings from "@/modals/EditMessageTemplateSettings.vue";
import moment from "moment";

export default defineComponent({
    name: "MessagesScheduling",
    components: {
        IonPage,
        IonContent,
        IonIcon,
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle,
        IonButton,
        IonItem,
        IonLabel,
        IonDatetime
    },
    computed: {
        messagesTemplates(){
            if(this.$store.getters.account){
                return this.$store.getters.account.messagesTemplates
            }
            return null
        },
        maxMessageDate(){
            const account = this.$store.getters.account
            return moment(account.eventDate).add(1, 'weeks').format('YYYY-MM-DD')
        },
        minMessageDate(){
            const account = this.$store.getters.account
            const date = moment(account.eventDate).subtract(1, 'month')
            const now = moment()
            if(date < now){
                return now.format('YYYY-MM-DD')
            }
            return date.format('YYYY-MM-DD')
        }
    },
    data(){
        return {
            icons: {
                createOutline
            },
            types: {
                'round-1' : 'תאריך ושעה לסבב ראשון',
                'round-2' : 'תאריך ושעה לסבב שני',
                'round-3' : 'תאריך ושעה לסבב שלישי',
                'round-4' : 'תאריך ושעה לסבב רביעי',
                'round-5' : 'תאריך ושעה לסבב חמישי',
            }
        }
    },
    methods:{
        async editMessageParams(){
            const modal = await modalController.create({
                component: EditMessageTemplate
            })

            await modal.present()
            const {data, role} = await modal.onDidDismiss()
            if(role === 'save' && data){
                await this.$store.dispatch('updateAccount', Object.assign({}, data))
                await this.$store.dispatch('loadData')
            }

        },
        async msgTempChanged(messageTemplate: any){
            const msg = JSON.parse(JSON.stringify(messageTemplate))
            const {status} = await this.$store.dispatch('messageTemplateSave', msg)
            if(status === 200){
                const toast = await toastController.create({
                    message: "נשמר בהצלחה",
                    duration: 1500,
                    position: 'middle'
                })

                await toast.present()
            }
        },
        async editMsgTemp(messageTemplate: any){
            const modal = await modalController.create({
                component: EditMessageTemplateSettings,
                componentProps: {
                    input: messageTemplate
                }
            })

            await modal.present()

            const {role, data} = await modal.onDidDismiss()
            if(role === 'save'){
                await this.msgTempChanged(data)
                await this.$store.dispatch('loadData')
            }
        }
    }
})
