
import {
    alertController,
    IonButton,
    IonButtons,
    IonContent, IonDatetime,
    IonHeader,
    IonIcon, IonInput,
    IonItem, IonLabel, IonList, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar,
    modalController
} from "@ionic/vue";
import {arrowForwardOutline, checkmarkOutline} from "ionicons/icons";
import {defineComponent} from "vue";
import moment from "moment";

export default defineComponent({
    name: "EditMessageTemplate",
    components: {
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonIcon,
        IonTitle,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonDatetime
    },
    computed: {
        initialForm(){
            const account = this.$store.getters.account
            return {
                eventType: account.eventType,
                eventHosts: account.eventHosts,
                locationName: account.locationName,
                eventDate: account.eventDate
            }
        },
        maxEventDate(){
            return moment().add(2, 'years').format('YYYY-MM-DD')
        },
        minEventDate(){
            return moment().add(14, 'days').format('YYYY-MM-DD')
        }
    },
    data(){
        return {
            icons: {
                arrowForwardOutline,
                checkmarkOutline
            },
            form: {
                eventType: '',
                eventHosts: '',
                locationName: '',
                eventDate: ''
            }
        }
    },
    methods: {
        async back(){
            if(JSON.stringify(this.initialForm) !== JSON.stringify(this.form)){
                const alert = await alertController.create({
                    message: 'לצאת מבלי לשמור שינויים ?',
                    buttons: [
                        {
                            text: 'ביטול',
                            role: 'cancel'
                        },
                        {
                            text: 'אישור',
                            role: 'confirm'
                        }
                    ]
                })

                await alert.present()
                const {role} = await alert.onDidDismiss()
                if(role === 'confirm'){
                    await modalController.dismiss(null, 'cancel')
                }
            }else{
                await modalController.dismiss( null, 'cancel')
            }
        },
        async save(){
            await modalController.dismiss( this.form, 'save')
        }
    },
    mounted(){
        this.form = JSON.parse(JSON.stringify(this.initialForm))
    }
})
